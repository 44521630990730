* {box-sizing:border-box;}

.bebas {font-family:'Bebas Neue Bold';}
.glober {font-family:'Glober';}
.b {font-weight:bold;}
.c {text-align:center;}

@yellow: #ffe400;
@darkyellow: #ffda00;
@white: #ffffff;
@darkwhite: #f0f0f0;
@blue: #89d6ff;
@brown: #6c6c6c;

.border(@radius) {border-radius:@radius; -webkit-border-radius:@radius; -moz-border-radius:@radius;}
.lead {font-size:24px; @media (max-width: 767px) {font-size:20px;} }


h1, h2, h3, h4 {.bebas; font-weight:normal;}

h1 {
    font-size:80px;
    .c;
    
    @media (max-width: 767px) {font-size:50px;}
}

h2 {
    font-size:45px;
    @media (max-width: 767px) {font-size:40px;}
}

h3 {
    font-size:30px;
    @media (max-width: 767px) {font-size:30px;}
}

body, input {.glober;}

body {
    font-size:20px;
    color:black;
    position:absolute;
    width:100%;
    overflow-x:hidden;
    
}

.container {
    min-height:1px;
    overflow:hidden;
}

.btn {
    display:inline-block;
    font-size:20px;
    padding:10px 28px;
    .border(10px);
    color:black !important;
    text-decoration:none !important;
    border:none;
    font-weight:normal;
    
    &.yellow, &.yellow:visited, &.yellow:active {
        box-shadow: inset 0 -4px 0 @darkyellow;
        background:@yellow;
        color:black !important;
        text-decoration:none !important;
    }
    
    &.white, &.white:visited, &.white:active {
        box-shadow: inset 0 -4px 0 @darkwhite;
        background:@white;
        color:black !important;
        text-decoration:none !important;
    }
    
    &:hover {
        color:black !important;
        text-decoration:none !important;
        opacity:0.9;
    }
    
}

header {
    font-size:20px;
    color:black;
    margin-top:40px;
    
    @media (min-width: 992px) and (max-width: 1199px) { .b; font-size:16px; }
    
    .logo {
        @media (min-width: 768px) and (max-width: 991px) {.c;}
        @media (max-width: 767px) {.c;}
    }
    
    .phone {
        padding-top:16px;
        
        @media (min-width: 768px) and (max-width: 991px) {.c;}
        @media (max-width: 767px) {.c;}
    }
    
    .mail {
        text-align:right;
        padding-top:16px;
        
        @media (min-width: 768px) and (max-width: 991px) {.c;}
        @media (max-width: 767px) {.c; padding-top:0;}
        
        a {
            color:black;
            text-decoration:underline;
            
            &:hover {
                text-decoration:none;
            }
        }
    }
    
    .count {
        text-align:right;
        
        @media (min-width: 768px) and (max-width: 991px) {.c; padding-top:20px;}
        @media (max-width: 767px) {.c; padding-top:20px;}
    }
}

.main {
    .c;
     .container {@media (min-width: 768px){padding-bottom:100px;}}
    
    .killer {
        padding-top:100px;
        font-size:200px;
        .bebas;
        line-height:1.1;
        
        
        @media (min-width: 768px) and (max-width: 991px) {font-size:150px;}
        @media (max-width: 767px) {font-size:70px;}
        
        
        span {
            color:white;
            background:#ff0000;
            padding:0px 120px;
            line-height:1;
            
            @media (min-width: 768px) and (max-width: 991px) {padding:0px 70px;}
            @media (max-width: 767px) {padding:0 20px;}
        }
    }
    
    h1 {
        margin:20px 0 0 0;
        padding:0;
    }
    
    .lead {
        
        @media (min-width: 768px) and (max-width: 991px) {padding:0px 40px;}
    }
    
    h2 {
        margin:60px 0 0px 0;
    }
    
    .b {
        .lead;
        margin-top:10px;
        
         @media (min-width: 992px) and (max-width: 1199px) { padding:0 150px; }
    }
    
    .btn {
        margin-top:30px;
    }
    
    .yes {
        position:relative;
        z-index:2;
        margin-right:-1000px;
        margin-top:-100px;
        
         @media (min-width: 992px) and (max-width: 1199px) { margin-right:-820px; margin-top:-130px; }         
         @media (min-width: 768px) and (max-width: 991px) {display:none;}
         @media (max-width: 767px) {display:none;}
    }
    
    &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 50vh solid transparent;
	    border-right: 100vw solid @blue;	    
	    height: 1090px;
	    width: 0px;
        position: absolute;
        top:200px;
        left: 0;
        z-index:-1;
        
         @media (min-width: 2500px) {height:1200px;}
         @media (min-width: 992px) and (max-width: 1199px) {height:1150px;}
         @media (min-width: 768px) and (max-width: 991px) {height:1190px;}
         @media (max-width: 767px) {height:2150px;border-right: 100vw solid @blue;}
    }
}

.forget {
    margin-top:100px;
    @media (max-width: 767px) {margin-top:75px; padding:75px 0; background:@yellow;}
    
    h1 {
        margin-bottom:10px;
        
        @media (min-width: 768px) and (max-width: 991px) {margin-top:0px;}
        @media (max-width: 767px) {line-height:1;margin-top:0px;}
    }
    
    h3 {
         @media (min-width: 992px) and (max-width: 1199px) { margin-bottom:10px; }
         @media (min-width: 768px) and (max-width: 991px) {margin-bottom:0;}
         @media (max-width: 767px) {margin-bottom:10px;}
    }
    
    .lead {
        .c;
        .b;
        padding-bottom:50px;
    }
    
    .leftcolumn {
        text-align:right;
        font-size:16px;
        
        @media (max-width: 767px) {.c; margin-top:170px;}
        
        
        a {
            color:#00a7ff;
            text-decoration:underline;
        
            &:hover {
             text-decoration:none;
            }
        }
    }
    
    .rightcolumn {
        font-size:16px;
        
        @media (min-width: 768px) and (max-width: 991px) {padding-top:30px;}
        @media (max-width: 767px) {.c;}
    }
    
    .image {
        
        
        &.gin {
            text-align:left;
            
             @media (min-width: 992px) and (max-width: 1199px) { padding-top:20px; img {max-width:110%;} }
             @media (min-width: 768px) and (max-width: 991px) {.c; img {max-width:70%;}}
             @media (max-width: 767px) {.c; margin-top:-330px;}
        }
        
        &.r2d2 {
            padding-top:30px;
            text-align:right;
            
             @media (min-width: 992px) and (max-width: 1199px) { img {max-width:80%;} }
             @media (min-width: 768px) and (max-width: 991px) { .c;  img {max-width:80%;} }
             @media (max-width: 767px) {.c; padding-top:60px;}
        }
    }
    
    .button {
        text-align:center;
        margin-top:70px;
    }
    
    
    
    &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 0vh solid transparent;
	    border-left: 100vw solid @yellow;	    
	    height: 1070px;
	    width: 0px;
        position: absolute;
        top:1000px;
        left: 0;
        z-index:-2;
        
         @media (min-width: 2500px) {height:1120px;}
         @media (min-width: 768px) and (max-width: 991px) {height:1340px;}
         @media (max-width: 767px) {display:none;}
    }
}

.howmany {
    color:white;
    margin-top:230px;
    
    @media (max-width: 767px) {margin-top:0px; padding:75px 0; background:#808080;}
    @media (min-width: 992px) and (max-width: 1199px) {margin-top:170px;}
    
    h1 {
        line-height:1.1;
        margin-bottom:0;
        
         @media (min-width: 768px) and (max-width: 991px) {margin-top:0px; margin-bottom:10px;}
    }
    
    .lead {
        .c;
        .b;
        font-size:24px;
        margin-top:10px;
    }
    
    
    
    .range-container {
        width:70%;
        margin:100px auto 0 auto;
        
        input {
            opacity:0;
        }
    }
    
    .bigcolumn {
        padding:0 30px;
        margin-top:90px;
        
        
        @media (min-width: 992px) and (max-width: 1199px) { padding: 0 20px; }
        @media (min-width: 768px) and (max-width: 991px) {padding:0 10px; margin-top:120px;}
        @media (max-width: 767px) {display:none;}
        
        
        .status {
            display:none;
            font-size:16px;
            .c;
            color:@yellow;
            position:relative;
            z-index:3;
            margin-top:-40px;
            
            @media (min-width: 768px) and (max-width: 991px) {font-size:14px;}
        }
        
        &.activecolumn {
            @media (min-width: 1199px) {margin-top:88px;}
            @media (max-width: 767px) {display:block;}
             
            .price-column {
                border-color:@yellow;
            }
            
            .status {
                display:block;
                padding:10px 0;
            }
        }
    }
    
    .price-column {
        border-radius: 10px;
        background-color: rgb(108, 108, 108);
        color:white;
        border:7px solid rgb(108, 108, 108);
        padding:33px;
        box-sizing:border-box;
        
        @media (min-width: 992px) and (max-width: 1199px) { padding: 33px 20px; }
        @media (min-width: 768px) and (max-width: 991px) {padding:33px 10px;}
        
        
        .image {
            .c;
        }
        
        h3 {
            .c;
            margin:10px 0 20px 0;
        }
        
        ul {            
            margin:0;
            padding:0;
            li {
                list-style:none;
                display:block;
                border-top:1px solid #808080;
                padding:5px 10px;   
                font-size:14px;
                
                @media (min-width: 992px) and (max-width: 1199px) { padding:7px 10px; }
                @media (max-width: 767px) { padding:7px 10px; }
                
                img {
                    margin-right:5px;
                    
                    @media (min-width: 992px) and (max-width: 1199px) { display:block; float:left; margin-right:10px; }
                    @media (min-width: 768px) and (max-width: 991px) { display:block; float:left; margin-right:10px; }
                    @media (max-width: 767px) { display:block; float:left; margin-right:10px; margin-bottom:10px;}
                }
            }
        }
        
        .btn {
            font-size:18px;
        }
    }
    
    &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 0vh solid transparent;
	    border-left: 100vw solid #808080;	    
	    height: 1800px;
	    width: 0px;
        position: absolute;
        top:1900px;
        left: 0;
        z-index:-3;
        
        
        @media (min-width: 1200px) {height:1900px; top:1800px;}        
        @media (min-width: 992px) and (max-width: 1199px) {height:1830px;}
        @media (min-width: 768px) and (max-width: 991px) {height:2160px;}
        @media (max-width: 767px) {display:none;}
        @media (min-width: 2500px) {height:2030px;top:1800px;}
    }
}

.sweet {
    margin-top:170px;
    .b;
    font-size:18px;
    
    @media (min-width: 992px) and (max-width: 1199px) { margin-top:180px; }
    @media (min-width: 768px) and (max-width: 991px) {font-size:16px;}
    @media (max-width: 767px) {margin-top:0px; padding:75px 0; background:@blue;}
    @media (min-width: 2500px) { margin-top:200px; }
    
    h1 {
        @media (max-width: 767px) {margin:0;}
    }
    
    
    .leftcolumn {
        text-align:right;
        padding-top:40px;
        padding-right:40px;
         @media (min-width: 768px) and (max-width: 991px) {padding-top:20px; padding-right:15px;}
         @media (max-width: 767px) {.c;padding:15px;}
    }
    
    .image {
        @media (min-width: 768px) and (max-width: 991px) {padding-top:10px;}
        @media (max-width: 767px) {.c;}
        
        img {
            max-width:100%;
            
            @media (max-width: 767px) {max-width:50%;}
        }
    }
    
    .rightcolumn {
        padding-top:20px;
        padding-left:40px;
        
        @media (min-width: 768px) and (max-width: 991px) {padding-top:0px; padding-left:15px;}
        @media (max-width: 767px) {.c; padding:15px;}
    }
    
    .button {
        text-align:center;
        margin-top:50px;
    }
    
     &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 0vh solid transparent;
	    border-right: 100vw solid @blue;	    
	    height: 870px;
	    width: 0px;
        position: absolute;
        top:3500px;
        left: 0;
        z-index:-4;
        
        
        @media (min-width: 992px) and (max-width: 1199px) { height:820px; top:3530px;}
        @media (min-width: 768px) and (max-width: 991px) {height:1160px;}
        @media (max-width: 767px) {display:none;}
        @media (min-width: 2500px) { height:930px; top:3530px;}
    }
}

.professionals {
    margin-top:170px;    
    font-size: 16px;
    
    @media (min-width: 2500px) {margin-top:200px;}
    @media (min-width: 992px) and (max-width: 1199px) {margin-top:120px;}
    @media (max-width: 767px) {background:@yellow; margin-top:0; padding:75px 0;}
    
    h1 {
        @media (min-width: 992px) and (max-width: 1199px) { line-height:1; margin: 80px 0 30px 0; }
        @media (min-width: 768px) and (max-width: 991px) {line-height:1;}
        @media (max-width: 767px) {line-height:1;}
    }
    
    h3 { 
        @media (min-width: 992px) and (max-width: 1199px) {line-height: 1; margin: 50px 0 20px 0; }
        @media (min-width: 768px) and (max-width: 991px) {margin-bottom:0;}
        @media (max-width: 767px) {line-height:1; margin-bottom:10px;}
    
    }
    
    .leftcolumn {
        text-align:right;
        @media (max-width: 767px) {.c; margin-top:100px;}
        
    }
    
    .image {
        text-align:center;
        padding-top:40px;
        
        &.brain, &.computer {
            @media (max-width: 767px) {margin-top:-270px; margin-bottom:100px;}
            @media (max-width: 500px) {margin-top:-300px; margin-bottom:100px;}
            @media (max-width: 420px) {margin-top:-320px; margin-bottom:100px;}
            @media (max-width: 380px) {margin-top:-350px; margin-bottom:100px;}
            @media (max-width: 370px) {margin-top:-380px; margin-bottom:100px;}
            @media (max-width: 290px) {margin-top:-420px; margin-bottom:100px;}
        }
        
         &.dontplay, &.bar{
            @media (max-width: 767px) {margin-top:20px; }
        }
        
        &.computer{
            @media (min-width: 768px) and (max-width: 991px) {padding-bottom:60px;}
        }
        
            
        
        @media (min-width: 768px) and (max-width: 991px) {padding-bottom:40px;}
        @media (max-width: 767px) {.c;}
    }
    
    .rightcolumn {
        @media (max-width: 767px) {.c; margin-bottom:30px;}
    }
    
    .button {
        text-align:center;
        margin-top:50px;
    }
    
    &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 0vh solid transparent;
	    border-left: 100vw solid @yellow;	    
	    height: 1450px;
	    width: 0px;
        position: absolute;
        top:4000px;
        left: 0;
        z-index:-5;
        
        @media (min-width: 992px) {height:1490px;}
        @media (min-width: 992px) and (max-width: 1199px) {height:1440px;}
        @media (min-width: 768px) and (max-width: 991px) {height:1880px;}
        @media (max-width: 767px) {display:none;}
        @media (min-width: 2500px) {height:1590px;}
    }
}

.use {
    margin-top:100px;
    .c;
    font-size:16px;
    color:white;
    
    @media (min-width: 768px) and (max-width: 991px) {margin-top: 100px;}
    @media (max-width: 767px) {margin-top:0px; padding:75px 0; background:@brown; }
    
    h1 {
        padding-bottom:0;
        margin-bottom:0;
        
        span {
            display:inline-block;
            color:white;
            background:#ff0000;
            padding:10px 80px;
            line-height:1;
            font-size:120px;
            
            @media (min-width: 992px) and (max-width: 1199px) {line-height:1.2;}
            @media (min-width: 768px) and (max-width: 991px) { line-height:1.2; padding:5px 50px 0px 50px;}
            @media (max-width: 767px) {font-size:70px; padding:10px 30px;}
            @media (max-width: 767px) {font-size:60px; padding:10px;}
            
        }
        
        @media (min-width: 992px) and (max-width: 1199px) {margin-bottom: 30px;line-height: 1;margin-top: 0;}
        @media (min-width: 768px) and (max-width: 991px) {margin-bottom: 30px; margin-top: 0;line-height:1; }
        @media (max-width: 767px) {margin-bottom: 30px; margin-top: 0;line-height:1; }
    }
    
    .lead {
        margin:20px 0 80px 0;
        
        @media (min-width: 992px) and (max-width: 1199px) {margin-bottom:50px; font-size:24px;}
        @media (min-width: 768px) and (max-width: 991px) {margin-bottom:50px; font-size:24px;}
        @media (max-width: 767px) {margin-bottom:40px;}
    }
    
    h3 {
        color:@yellow;
        @media (min-width: 992px) and (max-width: 1199px) {line-height:1;}
        @media (min-width: 768px) and (max-width: 991px) {line-height:1;}
        @media (max-width: 767px) {line-height:1; margin:10px 0 0 0;}
    }
    
    p {
        @media (min-width: 992px) and (max-width: 1199px) {font-size: 14px; margin-top: -10px;}
        @media (min-width: 768px) and (max-width: 991px) {font-size: 14px; margin-top: -10px;}
        @media (max-width: 767px) {padding-bottom:30px; }
    }
    
    .button {
        margin-top:70px;
        
        @media (min-width: 992px) and (max-width: 1199px) {margin-top:40px;}
        @media (min-width: 768px) and (max-width: 991px) {margin-top:40px;}
        @media (max-width: 767px) {margin-top:0;}
    }
    
    &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 0vh solid transparent;
	    border-left: 100vw solid @brown;	    
	    height: 1230px;
	    width: 0px;
        position: absolute;
        top:5100px;
        left: 0;
        z-index:-6;
        
        
        @media (min-width: 992px) and (max-width: 1199px) {height:1120px;}
        @media (min-width: 768px) and (max-width: 991px) {height:1680px;}
        @media (max-width: 767px) {display:none;}
        @media (min-width: 2500px) {height:1370px;}
    }
}

.recomendation {
    margin-top:170px;
    
    @media (min-width: 992px) and (max-width: 1199px) {margin-top:150px}
    @media (min-width: 768px) and (max-width: 991px) {margin-top:140px}
    @media (max-width: 767px) {margin-top:0px; padding-top:75px; background:@blue;}
    
    h1 {
        @media (min-width: 768px) and (max-width: 991px) {padding:0 40px; line-height:1;}
        @media (max-width: 767px) {line-height:1;}
    }
    
    &::before {
        display: block;
        content: "";
        border-bottom: 20vh solid transparent;
        border-top: 0vh solid transparent;
	    border-left: 100vw solid @blue;	    
	    height: 850px;
	    width: 0px;
        position: absolute;
        top:6100px;
        left: 0;
        z-index:-7;
        
        
        @media (min-width: 992px) and (max-width: 1199px) { top:6000px; height:870px;}
        @media (min-width: 768px) and (max-width: 991px) { height:1360px;}
        @media (max-width: 767px) { display:none;}
        @media (min-width: 2500px)  {top:6000px; height:1100px; }
    }
    
}

.fotorama {    
    overflow-y:visible !important;
    @media (max-width: 767px) { background:@blue;}
                
    .fotorama__arr--prev {
        margin: -41px 0 0 10%;
        background: url("../img/prev.png");
        
        @media (min-width: 992px) and (max-width: 1199px) {margin-left:30px;}
        @media (min-width: 768px) and (max-width: 991px) {margin-left:10px;}
        @media (max-width: 767px) {margin-left:0px;}
    }
        
    .fotorama__arr--next {
        margin: -41px 10% 0 0;
        background: url("../img/next.png");
        
        @media (min-width: 992px) and (max-width: 1199px) {margin-right:30px;}
        @media (min-width: 768px) and (max-width: 991px) {margin-right:10px;}
        @media (max-width: 767px) {margin-right:0px;}
    }
        
    .fotorama__arr--prev,
    .fotorama__arr--next {
        background-repeat: no-repeat;
        background-position: center;
        width:26px;
        height:41px;
    }  
    
    .fotorama__wrap, .fotorama__stage {
        overflow-y:visible !important;
       
        
        @media (max-width: 320px) {height:750px !important;}
        @media (max-width: 385px) {height:600px !important;}
        @media (max-width: 430px) {height:400px !important;}
        @media (max-width: 767px) {min-height:300px !important;}
        @media (min-width: 768px) and (max-width: 991px) {min-height:150px !important;}
    }
    .slide {
        /*display:flex;
        align-items:center;*/
    }
    
    .container {
        padding:0 70px;
    }
        
    .imagecontainer {
        
        .image {
            float:right;
            width:100px;
            height:100px;
            .border(50%);
            overflow:hidden;
            
            
            @media (max-width: 767px) {float:none; margin:0 auto 20px auto;}
            
            img {
                width:100px;
            }
        }
    }
    
    .text {
        .big {
            font-size:22px;
            .b;
            margin-bottom:20px;
            line-height:1.2;
             @media (min-width: 768px) and (max-width: 991px) {font-size:18px;}
             @media (max-width: 767px) {font-size:17px; .c;}
             @media (max-width: 430px) {font-size:15px;}
        }
        
        .small {
            font-size:16px;
            
            @media (max-width: 767px) { .c;}
            @media (max-width: 430px) {font-size:13px;}
        }
    }
}

.button.container {
    .c;
    margin-top:30px;
    @media (min-width: 992px) and (max-width: 1199px) {margin-top:30px;}
    @media (min-width: 768px) and (max-width: 991px) {margin-top:30px;}
    @media (max-width: 767px) {margin-top:0px; padding: 30px 0 75px 0; background:@blue;}
}

footer {
   margin-top:150px;
   padding-bottom:100px;
   font-size:16px;
   
   @media (max-width: 767px) {margin-top:50px; font-size:14px;}
   
   @media (min-width: 992px) and (max-width: 1199px) {margin-top:200px;}
    
    .made {
        float:right;
        
        @media (max-width: 767px) {width:280px; float:none; margin:0 auto;}
        
        .image {
            float:left;
            margin-right:15px;
        }
        
        .text {
            float:right;
            padding-top:3px;
            
            a, a:visited, a:active {
                color:#60c1f4;
                text-decoration:underline !important;
                
                &:hover {
                    text-decoration:none !important;
                }
            }
        }
    }
}


.box-modal {
	position: relative;
	padding: 30px 30px 50px 30px;
	background: #fff;
	
	 @media (min-width: 768px) and (max-width: 991px) {width:700px !important;}
	 @media (max-width: 767px) {width:100% !important; padding:10px 10px 30px 10px;}
	
	&.success {
	    background:@blue;
	    
	    .lead {
	        .c;
	        margin:40px 0;
	    }
	    
	    .box-modal_close { color: white; cursor: pointer; }
        .box-modal_close:hover { opacity:0.7; }
	}
	
	h1 {
	    margin:0;
	    padding:0;
	    
	    @media (max-width: 767px) {line-height:1; padding-top:50px;}
	}
	
	input {
	    background-color: rgb(249, 249, 249);
	    box-shadow: inset 0px 4px 0px rgba(239, 239, 239, 0.4);
        width: 620px;
        margin:20px auto;
        height: 44px;
        display:block;
        border:none;
        outline:none;
        padding:10px 20px;
        color:black;
        font-size:20px;
        
         @media (min-width: 992px) and (max-width: 1199px) {width:460px;}
         @media (max-width: 767px) {width:90%;}
	}
	
	#errorfield {
	    color:#ee1919;
	    font-size:16px;
	    .c;
	    padding:10px 0;
	    height:50px;
	    
	    @media (max-width: 767px) {font-size:14px;padding-top:0;}
	}
	
	.form_style input.rf_error, input.wrong_mail_field{
  background-color:#ffe0e0;
  box-shadow: inset 0px 4px 0px rgba(238, 25, 25, 0.4);
  color:#ee1818;
 }


input.rf_ok {
  background-color: rgb(243, 255, 241);
  box-shadow: inset 0px 4px 0px rgba(74, 192, 59, 0.4);
  color:#4ac03b;
}



/*.form_style .disabled,
.form_style .disabled:hover {
  opacity:0.4;
  cursor: default !important;
  outline:none !important;
}*/

#somethingwrong {
    position:absolute;
    margin-top:-200px;
    margin-left:20px;
    opacity:0;
    width:232px;
    height:75px;
    background:url('../img/somethingwrong.png') no-repeat;
    display:inline-block;
    z-index:10000000000;
    
    @media (max-width: 767px) {margin-top:-270px; margin-left:0px; width:284px;    height:40px;    background: url('../img/somethingwrong3.png') no-repeat;}
    
    @media (min-width: 768px) and (max-width: 991px) {margin-top:-290px;margin-left:400px; width:184px;    height:91px;    background:url('../img/somethingwrong2.png') no-repeat;}
    
    @media (min-width: 992px) and (max-width: 1199px){margin-left:0px;}
    
}

}
.box-modal_close { font-size:40px; position: absolute; right: 26px; top: 6px; color: #999; cursor: pointer; }
.box-modal_close:hover { color: #666; }






::-webkit-input-placeholder {color:black;}
::-moz-placeholder          {color:black;}/* Firefox 19+ */
:-moz-placeholder           {color:black;}/* Firefox 18- */
:-ms-input-placeholder      {color:black;}